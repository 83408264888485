import { Button, Link } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActionsTable } from 'components/actions-table'
import { CreateFormModalButton } from 'components/create-form-modal'
import { openModal as openRailsModal } from '../../../utils/open-modal'
import { SOURCE } from '../utils'
import { store } from 'store/index'
import { User } from 'store/modules/users'

type ActionsSectionProps = {
  user: User
}

export const ActionsSection = observer((props: ActionsSectionProps) => {
  const { user } = props

  if (!user) return null

  const actions = store.actions.incompleteForUser(user.id)

  const userSkills = store.userSkills.forUser(user.id)

  const canAddActions =
    user.isCurrentUser ||
    user.manager === store.currentUser ||
    store.currentUser?.isAdmin

  const onClick = () => {
    openRailsModal('/org_users/me/edit')
  }

  return (
    <section className="flex flex-col gap-4 w-full">
      <header className="flex items-center justify-between">
        <h2 className="font-bold mb-0 text-gray-900 text-xl">Actions</h2>
        <div className="flex gap-4">
          {user.isCurrentUser && (
            <Link
              className="flex items-center p-0"
              href={user.actionsUrl}
              underline={false}
              data-turbo-frame="content"
              data-turbo-action="advance"
            >
              View all
            </Link>
          )}
          {canAddActions && (
            <CreateFormModalButton
              className="bg-white flex items-center justify-center p-0"
              label={
                <p className="hover:text-theme-30 mb-0 text-theme-40">Add</p>
              }
              source={SOURCE}
              tabs={['action']}
              title="Add action"
              user={user}
            />
          )}
        </div>
      </header>
      {actions.length > 0 && (
        <ActionsTable
          actions={store.actions.sorted(actions)}
          characterLimit={180}
          editable={
            user.isCurrentUser ||
            user.manager === store.currentUser ||
            store.currentUser?.isAdmin
          }
          focusSkillIds={store.userSkills.focusedSkillIdsForUser(user.id)}
          skillsCharacterLimit={90}
          source={SOURCE}
        />
      )}
      {actions.length === 0 && user.isCurrentUser && (
        <>
          {userSkills.length > 0 ? (
            <>
              <div className="mb-5 text-gray-600 text-xs">
                Actions help you record what you’ll do next to progress in your
                skill and career growth.
              </div>
              <CreateFormModalButton
                className="btn max-w-none text-xs w-full"
                label="Add Action"
                source={`${SOURCE}-empty-state`}
                tabs={['action']}
                user={user}
                variant="outline"
              />
            </>
          ) : (
            <div className="text-gray-600 text-xs">
              {user.position ? (
                'You need to add skills to your position before you can add Actions.'
              ) : (
                <>
                  You need to add your position before you can add Actions -{' '}
                  <Button
                    className="bg-transparent focus:shadow-none inline p-0 text-gray-600 text-xs"
                    onClick={onClick}
                  >
                    add your position
                  </Button>
                </>
              )}
            </div>
          )}
        </>
      )}
    </section>
  )
})
